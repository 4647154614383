@font-face {
  font-family: 'Aeonik';
  src: local('Aeonik'), url('Aeonik-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Aeonik';
  font-weight: 500;
  src: local('Aeonik'), url('Aeonik-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Aeonik';
  font-weight: 700;
  src: local('Aeonik'), url('Aeonik-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Aeonik';
  font-weight: bold;
  src: local('Aeonik'), url('Aeonik-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Aeonik';
  font-weight: 900;
  src: local('Aeonik'), url('Aeonik-Black.otf') format('opentype');
}
